<template>
  <div class="wedding">
    <button class="wedding__locale-btn" @click="handleChangeLocale">
      <img class="wedding__locale-btn-img" :src="locale === 'ru' ? require('../assets/icons/ru.svg') : require('../assets/icons/kz.svg')" alt="">
    </button>
    <button class="wedding__music-btn" @click="musicOn">
      <lord-icon
        src="https://cdn.lordicon.com/yvsfcbjq.json"
        trigger="hover"
        colors="primary:#0a2e5c,secondary:#242424"
        style="width:22px;height:22px">
      </lord-icon>
    </button>
    <audio>
      <source src="../assets/audio/RaimDem.mp3"  />
    </audio>
    <div class="main">
      <div class="container">
        <div class="main__image-wrapper">
          <transition name="fade">
            <div v-if="isNamesShown" class="main__text main__text--top">
              M&A
            </div>
          </transition>
          <img class="main__image" src="../assets/images/MarsAida.jpeg" alt="">
          <transition name="fade">
            <div v-if="isNamesShown" class="main__text main__text--bottom">
              Marsel & Aida
              <div class="main__date">
                22.09.2024
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="container">
        <div class="description__wrapper">
          <div class="description__text" data-aos="fade-right" data-aos-duration="2000">
            {{$t('appeal')}}
          </div>
          <div class="description__text" data-aos="fade-right" data-aos-duration="2000">
            <div v-if="locale === 'kz'">{{$t('invitation.youKZ')}}</div>
            <div v-if="locale === 'kz'"><b>{{$t('invitation.names')}}</b></div>
            {{$t('invitation.main')}}
            <div v-if="locale === 'ru'"><b>{{$t('invitation.namesRU')}}</b></div>
          </div>
          <div class="description__place">
            <img data-aos="fade-right" data-aos-duration="2800" class="description__place-image" src="../assets/icons/flower2.svg" alt="">
            <div class="description__place-title">{{$t('party.title')}}</div>
            {{$t('party.dateTime')}}  | 18:00
            <div class="description__place-title">{{$t('location.title')}}</div>
            {{$t('location.name')}}
            <a href="https://go.2gis.com/8xjsg">
              <img class="description__2gis" src="../assets/images/2gis.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="schedule">
      <div class="container">
        <div class="schedule__header">{{ $t('plan.title') }}</div>
        <div class="schedule__wrapper">
          <div v-for="(item, index) in schedule" :key="item.title" class="schedule__item">
              <div class="schedule__icon">
                <img class="main__image" :src="require(`../assets/icons/${item.iconName}.svg`)" alt="">
              </div>
              <div class="schedule__time">
                {{item.time}}
              </div>
              <div class="schedule__title">
                {{ $t(item.text) }}
              </div>
              <div v-show="index !== schedule.length - 1" class="schedule__item-line" />
          </div>
          <div class="schedule__invitation">
            <img data-aos="fade-right" data-aos-duration="2800" class="schedule__invitation-image" src="../assets/icons/flower3.svg" alt="">
            {{ $t('joinUs.title')}} <br>
            <div class="schedule__invitation-title">{{ $t('joinUs.names')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="survey">
      <div class="container">
        <div class="survey__content">
          <div class="survey__text">
            <b>{{ $t('confirmation.title')}}</b>
          </div>
          <el-form ref="formRef" id="form">
            <el-form-item name="name" :label="$t('confirmation.fio')" aria-placeholder="_____ _________" prop="name">
              <el-input class="survey__input" v-model="formModel.name" placeholder="_____ _________" />
            </el-form-item>
            <el-form-item name="isCome" :label="$t('confirmation.isJoin')" prop="isCome">
              <el-radio-group v-model="formModel.isCome">
                <el-radio :value="'Буду'">{{$t('confirmation.isCome')}}</el-radio>
                <el-radio :value="'Будем вдвоем'">{{$t('confirmation.withSpouse')}}</el-radio>
                <el-radio :value="'Не буду'">{{$t('confirmation.cantCome')}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <transition name="slide-fade">
              <el-form-item v-if="formModel.isCome === 'Будем вдвоем'" name="spouse" aria-placeholder="_____ _________" :label="$t('confirmation.spouseFio')" prop="spouse">
                <el-input class="survey__input" v-model="formModel.spouse" placeholder="_____ _________"/>
              </el-form-item>
            </transition>
            <button
              @click="handleSendData"
              :disabled="isButtonDisabled"
              v-loading="isButtonDisabled"
              element-loading-background="rgba(122, 122, 122, 0.8)"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              type="submit"
              class="survey__form-button"
            >
              {{ $t('confirmation.sendButton')}}
            </button>
          </el-form>
        </div>
      </div>
    </div>
    <div class="time">
      <div class="container">
        <div class="time__wrapper">
          <div class="time__content" data-aos="fade-right" data-aos-duration="2000">
            <img data-aos="fade-right" data-aos-duration="3000" class="time__content-image" src="../assets/icons/flower1.svg" />
            <div class="time__text">
              {{$t('timer')}}
            </div>
            <Timer :date="new Date('2024-09-22T00:00:00')"/>
          </div>
        </div>
      </div>
    </div>
    <div class="photos">
      <div class="container">
        <div class="photos__wrapper">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('photosHint')"
            placement="top-start"
          >
            <div class="photos__link">
              <img class="photos__icon" src="../assets/images/getPhoto.jpg">
              <div class="photos__text">{{$t('photos')}}</div>
            </div>
          </el-tooltip>
        </div>
    </div>
  </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import Timer from "@/components/Timer"
import AOS from 'aos'
import { loaderSvg } from "@/constants/loader";
import { showSuccess } from "@/utils/notification";
import { useI18n } from 'vue-i18n'

// eslint-disable-next-line no-unused-vars
const { t, locale } = useI18n({useScope: 'global'})
const isMusicOn = ref(false)
const isNamesShown = ref(false)
const isButtonDisabled = ref(false)
const formRef = ref()
const formModel = reactive({
  name: '',
  spouse: '',
  isCome: 'Буду',
})
const URL_APP = 'https://script.google.com/macros/s/AKfycbzcD6hGSHoY1JOzoTet63-TFoc5-9XOjPfZg8wG2WblZ0aw8x2_QTD7oizYnYATHbzgEQ/exec'
const svg = loaderSvg

const schedule = [
  {
    iconName: 'guests',
    time: '18:00',
    text: 'plan.gathering'
  },
  {
    iconName: 'photos',
    time: '18:30',
    text: 'plan.photoSession'
  },
  {
    iconName: 'meeting',
    time: '19:00',
    text: 'plan.meeting'
  },
  {
    iconName: 'ceremony',
    time: '19:30',
    text: 'plan.ceremony'
  },
  {
    iconName: 'party',
    time: '20:00',
    text: 'plan.party'
  },
]

const handleSendData = async (ev) => {
  ev.preventDefault()

  const data = {
    name: formModel.name,
    isCome: formModel.isCome,
    spouse: formModel.spouse
  }

  console.log(data)
  let sendData = []
  for (let property in data) {
    if (property) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(data[property]);
      sendData.push(encodedKey + "=" + encodedValue);
    }

  }

  sendData = sendData.join("&");

  isButtonDisabled.value = true
  const result = await fetch(URL_APP, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    mode: "cors",
    body: sendData,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err, "Ошибка!"))
  // .then((res) => console.log(res));

  if( result.type === 'success' ) {
    formModel.name = ''
    formModel.isCome = 'Буду'
    formModel.spouse = ''


    showSuccess('Отлично!', 'Анкета успешно отправлена')
  }
  if( result.type === 'error' ) {
    alert(`Ошибка( ${result.errors}`)
  }
  isButtonDisabled.value = false
}

onMounted(() => {
  setTimeout(() => {
    isNamesShown.value = true
  }, 1000)

  const form = document.querySelector("#form")

  form.action = URL_APP

  AOS.init()
})

const musicOn = () => {
  isMusicOn.value = !isMusicOn.value
  const audioPlayer = document.getElementsByTagName('audio')[0];
  isMusicOn.value ? audioPlayer.play() : audioPlayer.pause()
}

const handleChangeLocale = () => {
  locale.value === 'ru' ? locale.value = 'kz' : locale.value = 'ru'
}
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
:deep(.questions__phone-link) {
  color: black;
}
:deep(.el-collapse-item__header) {
  height: 50px;
  font-family: 'Lora-Regular';
  line-height: 1.4;
  font-size: 26px;
  @include responsive(md) {
    height: 80px;
    font-size: 34px;
  }
}
:deep(.el-collapse-item__content) {
  padding-top: 16px;
  font-family: 'Lora-Regular';
  line-height: 1.4;
  font-size: 20px;
  @include responsive(md) {
    font-size: 28px;
  }
}
.wedding {
  font-family: 'Lora-Regular';
  &__music-btn {
    opacity: 0.8;
    z-index: 100;
    width: 32px;
    height: 34px;
    border-radius: 50%;
    position: fixed;
    top: 24px;
    left: 12px;
    background-color: #dde3e3;
  }
  &__locale-btn {
    cursor: pointer;
    opacity: 0.8;
    z-index: 100;
    width: 32px;
    height: 34px;
    position: fixed;
    top: 24px;
    right: 50px;
    background-color: transparent;
  }
  &__locale-btn-img {
    width: 60px;
    height: 30px;
  }
}
.main {
  position: relative;
  height: 100vh;
  margin: 0 -12px;
  font-size: 46px;

  &__image-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    line-height: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: "Suranna", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    color: white;
    @include responsive(md) {
      line-height: 1.2;
    }
    &--top {
      font-size: 92px;
      top: 70px;
      font-weight: 550;
      @include responsive(md) {
        top: 90px;
        font-size: 142px;
      }
    }
    &--bottom {
      font-size: 64px;
      bottom: 56px;
      @include responsive(md) {
        font-size: 80px;
        bottom: 80px;
      }
    }
  }

  &__date {
    font-family: 'Lora-Regular';
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 200;
    font-size: 52px;
    letter-spacing: 0.6px;
    @include responsive(md) {
      font-size: 68px;
    }
  }

  &__title {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__subtitle {
    position: absolute;
    text-align: center;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @include responsive(md){
      width: 540px;
    }

  }
}

.description {
  padding: 52px 0;
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
  }
  &__text {
    text-align: center;
    font-family: 'Lora-Regular';
    letter-spacing: 1.1px;
  }
  &__image-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 315px;
  }
  &__image {
    border-radius: 50% 0 50% 0;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
  &__place {
    margin: 34px 0;
    font-family: 'Lora-Regular';
    position: relative;
    width: 300px;
    border: 1px solid black;
    padding: 26px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include responsive(md) {
      width: 460px;
      font-size: 22px;
      padding: 28px 18px;
    }
  }
  &__2gis {
    margin-top: 4px;
    width: 32px;
    height: 32px;
  }
  &__place-title {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__place-image {
    position: absolute;
    bottom: -80px;
    right: -76px;
    width: 182px;
    height: 182px;
    transform: rotate(68deg);
    @include responsive(md) {
      bottom: -82px;
      right: -96px;
      width: 202px;
      height: 232px;
    }
  }

}

.schedule {
  font-family: 'Lora-Regular';
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    width: 120px;
    height: 120px;
  }
  &__time {
    font-weight: bold;
  }
  &__invitation {
    margin: 34px 0;
    font-family: 'Lora-Italic';
    position: relative;
    width: 320px;
    border: 1px solid black;
    padding: 26px 12px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2em;
    gap: 2px;
    @include responsive(md) {
      width: 460px;
      font-size: 22px;
      padding: 28px 18px;
    }
  }
  &__invitation-image {
    position: absolute;
    bottom: -75px;
    right: -76px;
    width: 162px;
    height: 162px;
    transform: rotate(336deg);
    @include responsive(md) {
      bottom: -79px;
      right: -119px;
      width: 202px;
      height: 202px;
    }

  }
  &__invitation-title {
    font-family: 'Lora-Regular';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
  }
  &__item-line {
    width: 3px;
    height: 22px;
    background: black;
    border-radius: 20px;
  }

}

.survey {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Lora-Regular';
    letter-spacing: 1.1px;
    gap: 26px;
    :deep(.el-loading-mask) {
      border-radius: 40px;
      background-color: rgb(211 225 184 / 80%) !important;
    }
    :deep(.el-loading-spinner) {
      .path {
        stroke: white!important;
      }
    }
  }
  &__text {
    margin-top: 30px;
    text-align: center;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__form-checkbox {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    input {
      width: 22px;
      height: 22px;
    }
  }
  :deep(.el-input__wrapper) {
    border-radius: 40px;
    padding: 8px 12px;
    &.is-focus{
      box-shadow: none;
    }
  }
  :deep(.el-form-item__label) {
    font-weight: 700;
  }
  :deep(.el-textarea__inner:focus) {
    box-shadow: 0 0 0 1px lightgray;
  }
  &__form-button {
    width: 100%;
    height: 48px;
    display: flex;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: transparent;
    border: 1px solid #000;
    color: #000;
    font-family: 'Lora-Regular';
    font-weight: bold;
    margin: 16px auto 0;
    letter-spacing: 1.2px;
    @include responsive(md) {
      width: 220px;
    }

  }
}

.time {
  font-family: 'Lora-Regular';
  padding:42px 0;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__content {
    margin: 34px 0;
    font-family: 'Lora-Regular';
    position: relative;
    width: 320px;
    border: 1px solid black;
    padding: 26px 12px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2em;
    gap: 14px;
    @include responsive(md) {
      width: 460px;
      font-size: 22px;
      padding: 28px 18px;
    }
  }
  &__content-image {
    position: absolute;
    left: -90px;
    top: -85px;
    width: 172px;
    height: 172px;
    transform: revert;
    @include responsive(md) {
      left: -110px;
      top: -105px;
      width: 242px;
      height: 242px;
    }
  }
  &__text {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
  }

  &__border {
    position: absolute;
    top: -140px;
    left: -160px;
    width: 152px;
    height: 152px;
    transform: rotate(127deg);
    @include responsive(md) {
      top: -140px;
      left: -160px;
      width: 300px;
      height: 300px;
    }
  }
}

.photos {
  font-family: 'Lora-Regular';
  padding: 0 0 32px;
  letter-spacing: 1.2px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  &__link {
    display: flex;
    max-width: 450px;
    align-items: center;
    justify-content: center;
    color: black;
  }
  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 14px;
  }
  &__text {
    font-size: 20px;
    text-transform: uppercase;
  }
}
.h2__hr {
  position: absolute;
  left: -1010px;
  top: 50%;
  width: 1000px;
  height: 1px;
  background: #000;
  @include responsive(md) {
    left: -1026px;
  }
}
</style>
